import React, { useState } from 'react';
import Headroom from 'react-headroom';
import Link from 'next/link';
import { Popover } from '@headlessui/react';
import { Spin as Hamburger } from 'hamburger-react';
import Image from 'next/image';
import data from '../../data/basic-data.json';

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <Headroom className="relative z-50">
      <header className="fixed top-0 left-0 right-0 z-50 px-5 bg-white md:py-2">
        <nav
          className="flex items-center justify-between max-w-xl py-2 mx-auto md:max-w-5xl 2xl:max-w-7xl "
          aria-label="Navegación"
        >
          <div className="flex lg:flex-1">
            <Link
              href="/"
              className="-m-1.5 p-1.5 relative !z-[999]"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">EntreCoach</span>
              <div className="relative w-40 h-12 mr-2 md:h-14 sm:w-40 md:w-52">
                <Image
                  fill
                  src="/images/jada-logo-black.svg"
                  alt="Logo EntreCoach"
                  className="object-contain object-center"
                  sizes="120px"
                  priority
                />
              </div>
            </Link>
          </div>
          <div className="relative flex items-center gap-4 lg:hidden">
            <Link
              href="/#paquetes"
              className="flex items-center px-5 py-3 text-xs font-semibold text-white transition-all duration-200 whitespace-nowrap sm:text-sm md:text-base payfair bg-brand-btn hover:bg-brand-btn "
            >
              Reservar Sesión
            </Link>

            <button
              type="button"
              className="!z-[999] cursor-pointer inline-flex items-center justify-center rounded-md px-2 py-4 sm:p-4 text-white"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              style={{ minWidth: '48px', minHeight: '48px', padding: '12px' }} // Aumenta el padding aquí
              aria-label="Toggle menu"
              aria-expanded={mobileMenuOpen}
            >
              <span className="sr-only">Menú</span>
              <Hamburger label="Show menu" color="#4e4237" toggled={mobileMenuOpen} />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-6">
            {data.navigation.map((item) => (
              <Link
                key={item.name}
                href={item.route}
                className="font-bold leading-6 text-brand-secondary hover:text-brand-dark"
                onClick={() => setMobileMenuOpen(false)}
              >
                {item.name}
              </Link>
            ))}
          </Popover.Group>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link
              href="/#paquetes"
              className="px-5 py-3 font-semibold text-white transition-all duration-200 payfair bg-brand-btn hover:bg-brand-btn "
              style={{ minWidth: '48px', minHeight: '48px' }}
            >
              Reservar Sesión
            </Link>
          </div>
        </nav>

        {mobileMenuOpen && (
          <div className="fixed inset-y-0 bottom-0 right-0 !z-50 lg:!hidden w-screen h-screen p-6 pt-10 overflow-y-auto bg-white/90 backdrop-blur sm:max-w-sm swing-in-top-fwd flex items-center">
            <div className="flow-root mt-10">
              <div className="-my-6 ">
                <div className="py-6 space-y-2">
                  {data.navigation.map((item) => (
                    <Link
                      key={item.name}
                      href={item.route}
                      className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg text-brand-btn "
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="">
                  <Link
                    href="https://prod.EntreCoach.com/#/login"
                    onClick={() => setMobileMenuOpen(false)}
                    className="flex items-center px-8 py-3 text-sm font-semibold text-white transition-all duration-200 whitespace-nowrap md:text-base payfair bg-brand-btn hover:bg-brand-btn "
                  >
                    Contacto
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
    </Headroom>
  );
}
