/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

function FormFieldAndError({
  btnSuccess,
  errors,
  touched,
  name,
  placeholder,
  extraClass,
  as,
  labelClassName,
  errorColor,
  labelText,
}) {
  return (
    <div className="relative w-full">
      <label
        htmlFor={name}
        className={`block text-sm font-medium leading-6 text-neutral-800 ${labelClassName}`}
      >
        {`${labelText}`}
      </label>
      <Field
        id={name}
        aria-label={name}
        autoComplete="off"
        as={as}
        type="text"
        {...(btnSuccess ? { value: '', readOnly: true } : {})}
        className={`block 
        w-full 
        px-2 
        py-2 
        bg-transparent
        max-h-[200px]
        border-2
        h-[45px]
        !placeholder-brand-dark 
        border-brand-main text-neutral-800  
        placeholder:text-brand-main
        sm:text-sm
        sm:leading-6  
        ${btnSuccess && 'pointer-events-none'
          }  ${extraClass} ${errors[name] && touched[name] && !btnSuccess
            ? ' border-red-500'
            : ''
          }`}
        placeholder={btnSuccess ? '¡Enviado!' : placeholder}
        name={name}
      />
      {errors[name] && touched[name] && !btnSuccess ? (
        <p
          className={` mt-1 absolute text-xs font-bold ${!errorColor ? 'text-red-500' : errorColor}`}
        >
          {errors[name]}
        </p>
      ) : null}
    </div>
  );
}

FormFieldAndError.propTypes = {
  btnSuccess: PropTypes.bool.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  extraClass: PropTypes.string,
  as: PropTypes.string,
  labelClassName: PropTypes.string,
  errorColor: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

FormFieldAndError.defaultProps = {
  placeholder: '',
  errorColor: '',
  as: 'input',
  extraClass: '',
};

export default FormFieldAndError;
