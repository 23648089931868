/* eslint-disable import/no-unresolved */
import React from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import data from '../../data/basic-data.json';
import 'swiper/css';
import 'swiper/css/free-mode';
import Newsletter from './Newsletter';

const ReactLottiePlayer = dynamic(
  async () => {
    const ReactLottie = await import('@lottiefiles/react-lottie-player');
    return ReactLottie.Player;
  },
  { ssr: false },
);
export default function Footer() {
  return (
    <section className="relative z-20 px-5 py-10 bg-brand-main-darker mt-[110px] md:mt-[100px]">
      <ReactLottiePlayer
        autoplay
        loop
        src="./lotties/footer.json"
        className=" w-[150px] h-[150px] sm:w-[200px] sm:h-[200px]  right-1/2 translate-x-1/2 absolute top-0 -translate-y-[130px] sm:-translate-y-[170px]"
      />
      <div className="relative z-10 w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-7xl">
        <Newsletter footer />

        <div className="w-full ">
          <div className="flex flex-col items-center justify-between gap-5 text-white md:flex-row">
            <p className="text-xl md:text-3xl payfair">Navegación</p>
            <div className="w-full bg-white  h-[1px]" />
            <div className="grid justify-center w-full grid-cols-3 gap-4 md:flex md:gap-10">
              {data.navigation.map((item) => (
                <Link
                  key={item.name}
                  href={item.route}
                  className="text-xs text-center lg:text-sm whitespace-nowrap "
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <h2 className="mx-auto block md:hidden my-8 text-xl text-white md:text-2xl w-fit payfair">
            Sígueme en
            {' '}
            <Link
              className="text-brand-main-light"
              target="_blank"
              href="https://instagram.com/entrecoach"
            >
              @entrecoach
            </Link>
          </h2>
        </div>
      </div>

      <div className="flex flex-col  justify-between w-full h-5 max-w-xl pb-10 mx-auto mt-10 text-white sm:flex-row md:max-w-5xl 2xl:max-w-7xl">
        <div className="mb-2">
          <div className="flex items-center justify-center text-xs md:text-base ">
            Diseñado por
            {' '}
            <Link
              href="https://www.colmenadigital.net"
              target="_blank"
              className="relative block w-32 h-6 ml-2"
            >
              <Image
                alt="Social"
                fill
                src="/images/colmena-digital-logo-alt.svg"
                className="object-contain"
              />
            </Link>
          </div>
        </div>
        <div>
          <p className="mx-auto hidden md:block  text-xl text-white md:text-base whitespace-nowrap w-fit payfair">
            Sígueme en
            {' '}
            <Link
              className="text-brand-main-light"
              target="_blank"
              href="https://instagram.com/entrecoach"
            >
              @entrecoach
            </Link>
          </p>
        </div>
        <div className="flex justify-center gap-5">
          <Link href="/politicas-de-privacidad" className="text-xs md:text-sm ">
            Políticas de privacidad
          </Link>
          <Link href="/terminos-y-condiciones" className="text-xs md:text-sm ">
            Términos y condiciones
          </Link>
        </div>
      </div>
    </section>
  );
}
