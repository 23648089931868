import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import '../styles/globals.css';
import { Roboto, Playfair_Display as PlayfairDisplay } from 'next/font/google';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import NextNProgress from 'nextjs-progressbar';
import AOS from 'aos';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import 'aos/dist/aos.css';

const roboto = Roboto({
  weight: ['400', '700'],
  subsets: ['latin'],
  variable: '--font-roboto',
});

const playfairDisplay = PlayfairDisplay({
  weight: ['400', '700'],
  subsets: ['latin'],
  variable: '--font-payfair',
});

export default function App({ Component, pageProps }) {
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-cubic',
      once: true,
      offset: 50,
    });
  }, []);
  if (typeof window !== 'undefined') {
    return (
      <main className={`${roboto.variable} ${playfairDisplay.variable}`}>
        <GoogleAnalytics trackPageViews gaMeasurementId="G-0VPYCWLB4S" />
        <NextNProgress color="#514b45" />
        <Header />
        <Component {...pageProps} />
        <Footer />
      </main>
    );
  }
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object.isRequired,
};
